import './style.css';
import {Map, View} from 'ol';
import {fromLonLat} from 'ol/proj';
import GPX from 'ol/format/GPX.js';
import VectorSource from 'ol/source/Vector.js';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import OSM, {ATTRIBUTION} from 'ol/source/OSM';

const openSeaMapLayer = new TileLayer({
  source: new OSM({
    attributions: [
      'All maps © <a href="https://www.openseamap.org/">OpenSeaMap</a>',
      ATTRIBUTION,
    ],
    opaque: false,
    url: 'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png',
  }),
});

if (typeof mylatpos == 'undefined') {
  mylatpos = 47.739;
}

if (typeof mylonpos == 'undefined') {
  mylonpos = 11.030;
}

const raster = new TileLayer({
  source: new OSM()
});

const style = {
  'Point': new Style({
    image: new CircleStyle({
      fill: new Fill({
        color: '#f00',
      }),
      radius: 5,
      stroke: new Stroke({
        color: '#ff0',
        width: 1,
      }),
    }),
  }),
  'LineString': new Style({
    stroke: new Stroke({
      color: '#f00',
      width: 3,
    }),
  }),
  'MultiLineString': new Style({
    stroke: new Stroke({
      color: '#0a0',
      width: 3,
    }),
  }),
};

const vector = new VectorLayer({
  source: new VectorSource({
    url: 'seestern.gpx',
    format: new GPX(),
  }),
  style: function (feature) {
    return style[feature.getGeometry().getType()];
  },
});

const map = new Map({
  target: 'map',
  layers: [raster, openSeaMapLayer, vector],
  view: new View({
    center: fromLonLat([mylonpos, mylatpos]),
    zoom: 10
  })
});
